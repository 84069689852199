import React from "react"
import "./style.scss"

const QualyFrameSegments = () => {
  return (
    <>
      <h1>QualyFrame Segments</h1>
    </>
  )
}

export default QualyFrameSegments
